import Kv from "stylesheets/js/top/_kv.js";

import Vue from "vue";
import Category from "src/views/frontend/home/category.vue";
import Pickup from "src/views/frontend/home/pickup.vue";
import Ranking from "src/views/frontend/home/ranking.vue";
import NewArrival from "src/views/frontend/home/newArrival.vue";
import Topic from "src/views/frontend/home/topic.vue";
import MyList from "src/views/frontend/home/myList.vue";
import Service from "src/views/frontend/home/service.vue";

import { Routes } from "src/routes";
Vue.prototype.Routes = Routes;

document.addEventListener("DOMContentLoaded", () => {
  // キービジュアル
  Kv();

  // カテゴリ検索
  const category = new Vue({
    render: h => h(Category)
  });
  category.$mount("#category");

  // 特集
  const pickup = new Vue({
    render: h => h(Pickup)
  });
  pickup.$mount("#pickup");

  // ランキング
  const ranking = new Vue({
    render: h => h(Ranking)
  });
  ranking.$mount("#ranking");

  // 新着
  const newArrival = new Vue({
    render: h => h(NewArrival)
  });
  newArrival.$mount("#newArrival");

  // トピック
  const topic = new Vue({
    render: h => h(Topic)
  });
  topic.$mount("#topic");

  // マイリスト
  const myList = new Vue({
    render: h => h(MyList)
  });
  myList.$mount("#myList");

  // サービス
  const service = new Vue({
    render: h => h(Service)
  });
  service.$mount("#service");
});
