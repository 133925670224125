<template>
  <section class="service">
    <div class="service-inner">
      <div class="headline-wrapper">
        <h2><span class="en font-lato">SERVICE</span><span class="ja">サービス一覧</span></h2>
      </div>
      <div class="boxes">
        <a v-for="service in services" :key="service.id" class="box" :href="serviceUrl(service)">
          <div v-if="service.main_image.url" class="pic">
            <img :src="service.main_image.url" />
          </div>
        </a>
      </div>
      <a class="more" href="/service_lists">
        もっと見る
        <div class="arrow">
          <span class="icon-arrow-right"></span>
        </div>
      </a>
    </div>
    <div class="white-line"></div>
  </section>
</template>

<script>
import Client from "src/api/client";
import Deserializer from "src/util/deserializer";

import { Routes } from "src/routes";

export default {
  data: function() {
    return {
      services: []
    };
  },
  beforeMount() {
    this.fetchService();
  },
  methods: {
    async fetchService() {
      const res = await Client.serviceList.fetchServiceList({ filter: { published: true }, sort: "default" });
      this.services = await Deserializer(res);
    },
    serviceUrl(service) {
      return service.link_url || Routes.servicePath(service.id);
    }
  }
};
</script>
